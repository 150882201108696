
import { useState } from 'react';

export default function BasicTextFields({value,onChange,error,helperText, height}) {
  const [hasContent, setHasContent] = useState(false);

  const handleFocus = () => {
    setHasContent(true);
  };

  const handleBlur = (event) => {
    if (event.target.value === '') {
      setHasContent(false);
    }
  };
  return (
    <div>
      <input
        type='text'
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`border-0 w-full h-${height} p-4 rounded-lg focus:outline-none bg-[#201F1F] text-[#B3B4B3] placeholder:text-[#B3B4B3] ${
          hasContent ? 'border-[#FFD700]' : ''
        }`}
        placeholder='Email Address'
      />
      {error && <p className='text-red-500'>{helperText}</p>}
    </div>
  );
}
