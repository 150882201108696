import React from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import GifLoading from "../Components/gifloading";
import Logo from "../Components/logo";
import TitleHeading from "../Components/title";
import Subtitle from "../Components/subtitle";
import TextBody from "../Components/textbody";
import CountdownTimer from "../Components/timer";
import BasicTextFields from "../Components/simpleinput";
import MultipleSelect from "../Components/selectinput";
import ButtonSubmit from "../Components/submit";
import GifLoadingMobile from "../Components/gifloadingmobile";
import SocialMediaBar from "../Components/socialMedia";
import { useNavigate } from "react-router-dom";

function Index() {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const [inputValue, setInputValue] = React.useState("");
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [mobileStep, setMobileStep] = React.useState(0);
  const [webStep, setWebStep] = React.useState(0);
  const [inputError, setInputError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleSubmit = async () => {
    if (inputValue === "" || !inputValue.includes("@")) {
      setInputError(true);
      setHelperText("Please enter a valid email address");
      //do not proceed
      return;
    } else {
      setInputError(false);
      setHelperText("");
    }
    if (selectedOption === null) {
      setInputError(true);
      setHelperText("Please select an option");
      return;
    } else {
      setInputError(false);
      setHelperText("");
    }
    fetch("https://api.nyoko.com/api/v1/get_feedback/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: "Nyoko Early Access Request Form Nyoko website",
        email: inputValue,
        subject: "Early Access Request",
        message: selectedOption,
      }),
    });
    setSelectedOption(null);
    setInputValue("");
    setWebStep(1);
    setMobileStep(2);

    // Update URL and create a new history entry
    window.history.pushState(null, "", "/thank-you");
  };

  // For mobile step change
  const handleMobileStepChange = (step) => {
    setMobileStep(step);
    if (step === 1) {
      window.history.pushState(null, "", "/early-access");
    } else if (step === 2) {
      window.history.pushState(null, "", "/thank-you");
    }
  };

  // Handle popstate event (back/forward button clicks)
  React.useEffect(() => {
    const handlePopState = () => {
      const path = window.location.pathname;
      if (path === "/") {
        setMobileStep(0);
        setWebStep(0);
      } else if (path === "/early-access") {
        setMobileStep(1);
        setWebStep(0);
      } else if (path === "/thank-you") {
        setMobileStep(2);
        setWebStep(1);
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  return (
    <div
      style={{
        padding: "0",
        margin: "0",
        overflow: "hidden",
        backgroundColor: "black",
        color: "white",
        fontFamily: "lato,sans-serif",
        width: "100vw",
        height: "100vh",
      }}
    >
      {isMobileSize ? (
        <div className="flex flex-col gap-4 w-100">
          {mobileStep === 0 && (
            <div className="flex flex-col gap-4 w-100 h-100 justify-center px-6">
              <GifLoadingMobile />
              <div className="flex justify-center">
                <Logo fontSize={"text-[1.2rem]"} />
              </div>
              <div className="flex items-start justify-center">
                <TextBody text={"We are launching in"} fontSize={"1xl"} />
              </div>

              <CountdownTimer targetDate={new Date("2024-10-01")} />
              <ButtonSubmit
                label={"Request Early Access"}
                onClick={() => handleMobileStepChange(1)}
              />
            </div>
          )}
          {mobileStep === 1 && (
            <div className="flex flex-col gap-2 w-100 h-100 justify-center p-6">
              <Logo />
              <TitleHeading
                title="Explore the Future of Photography in Qatar."
                fontSize={"1.688rem"}
              />
              <Subtitle text="Nyoko is an on demand photography platform that connects talented photographers to those looking to create lasting memories through photoshoots." />
              <TextBody text={"We are launching in"} fontSize={"1xl"} />
              <CountdownTimer targetDate={new Date("2024-10-01")} />

              <BasicTextFields
                value={inputValue}
                onChange={handleChange}
                error={inputError}
                helperText={helperText}
              />
              <MultipleSelect
                value={selectedOption}
                onChange={handleSelectChange}
              />
              <ButtonSubmit
                label={"Request Early Access"}
                onClick={handleSubmit}
              />
            </div>
          )}
          {mobileStep === 2 && (
            <div className="flex flex-col gap-4 w-100 h-100 justify-center p-6">
              <Logo />
              <div className="my-4" />
              <TitleHeading title="Thank you." fontSize={"1.688rem"} />
              <Subtitle text="You have been added to our waiting list. We will be in touch with you soon." />
              <div className="" />
              <SocialMediaBar isMobile={true} />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-row gap-4 w-100">
          <div className="flex flex-col gap-4 w-1/2 h-100">
            <GifLoading path={"/main.gif"} />
          </div>
          {webStep === 0 && (
            <div className="flex flex-col gap-4 w-1/2 h-screen  justify-center p-12">
              <Logo />
              <TitleHeading title="Explore the Future of Photography in Qatar." />
              <Subtitle text="Nyoko is an on demand photography platform that connects talented photographers to those looking to create lasting memories through photoshoots." />
              <TextBody text={"We are launching in"} />
              <CountdownTimer targetDate={new Date("2024-10-01")} />

              <BasicTextFields
                value={inputValue}
                onChange={handleChange}
                error={inputError}
                helperText={helperText}
              />
              <MultipleSelect
                value={selectedOption}
                onChange={handleSelectChange}
              />
              <ButtonSubmit
                label={"Request Early Access"}
                onClick={handleSubmit}
              />
            </div>
          )}
          {webStep === 1 && (
            <div className="flex flex-col gap-4 w-1/2 h-100 justify-start p-12">
              <Logo />
              <div className="my-4" />
              <TitleHeading title="Thank You" />
              <Subtitle text="You have been added to our waiting list. We will be in touch with you soon." />
              <div className="my-4" />
              <SocialMediaBar />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Index;
