import React from 'react';

const TitleHeading = ({ title, fontSize }) => {
    if (!fontSize) {
        fontSize = "2.5rem";
    }
    return (
        <div className={`text-white font-[montserrat] w-fit font-bold`} style={
            {
                fontSize: fontSize,
            }
        }>
            <h1 style={{ letterSpacing: "4px" }}>{title}</h1>
        </div>
    );
}

export default TitleHeading;